@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

body {
  @apply bg-rich-black text-white font-body antialiased;
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Custom scrollbar */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  @apply bg-dark-gray;
}

::-webkit-scrollbar-thumb {
  @apply bg-gold bg-opacity-50 hover:bg-gold transition-colors duration-200;
}

/* Smooth scrolling */
html {
  scroll-behavior: smooth;
}

/* Selection styling */
::selection {
  @apply bg-gold bg-opacity-30 text-white;
}

/* Input and button focus states */
input:focus, 
textarea:focus, 
button:focus {
  @apply outline-none ring-2 ring-gold ring-opacity-50;
}

/* Custom animations */
@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.animate-float {
  animation: float 3s ease-in-out infinite;
}

/* Image hover effects */
.image-hover {
  @apply relative overflow-hidden;
}

.image-hover img {
  @apply transition-transform duration-700 ease-out;
}

.image-hover:hover img {
  @apply scale-110;
}

.image-hover::after {
  content: '';
  @apply absolute inset-0 bg-gradient-to-t from-rich-black to-transparent bg-opacity-80 opacity-0 transition-opacity duration-300;
}

.image-hover:hover::after {
  @apply opacity-100;
}

/* Text gradient effect */
.text-gradient {
  @apply bg-clip-text text-transparent bg-gradient-to-r from-gold to-gold-light;
}

/* Loading animation */
.loading-dots div {
  @apply w-2 h-2 rounded-full bg-gold;
  animation: bounce 0.5s infinite;
}

.loading-dots div:nth-child(2) {
  animation-delay: 0.1s;
}

.loading-dots div:nth-child(3) {
  animation-delay: 0.2s;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}

/* Gallery image container */
.gallery-image {
  @apply relative overflow-hidden bg-dark-gray;
}

.gallery-image img {
  @apply w-full h-full object-cover transition-transform duration-700;
}

.gallery-image:hover img {
  @apply scale-110;
}

.gallery-image::after {
  content: '';
  @apply absolute inset-0 bg-gradient-to-t from-rich-black to-transparent bg-opacity-90 opacity-0 transition-opacity duration-300;
}

.gallery-image:hover::after {
  @apply opacity-100;
}

/* Button styles */
.btn-primary {
  @apply inline-block px-8 py-4 bg-gold text-rich-black font-display font-bold tracking-wide 
         hover:bg-gold-light transition-colors duration-300;
}

.btn-outline {
  @apply inline-block px-8 py-4 border-2 border-gold text-gold font-display font-bold tracking-wide 
         hover:bg-gold hover:text-rich-black transition-colors duration-300;
}

/* Form styling */
.form-input {
  @apply w-full bg-dark-gray border-2 border-gold border-opacity-20 text-white px-4 py-3 
         focus:border-gold transition-colors duration-300;
}

/* Mobile menu backdrop */
.mobile-menu-backdrop {
  @apply fixed inset-0 bg-rich-black bg-opacity-95 backdrop-blur-sm;
}
